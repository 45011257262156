import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown} from 'react-bootstrap'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'

//img
import user1 from '../../../assets/images/user/1.jpg'
 


import s1 from '../../../assets/images/page-img/s1.jpg'
import s2 from '../../../assets/images/page-img/s2.jpg'
import s3 from '../../../assets/images/page-img/s3.jpg'
 


import { channels, tabData } from './Chatdata'
import ChatTab from './ChatupSetting/ChatTab'
import AccountSetting from './ChatupSetting/AccountSetting'
import PrivacySetting from './ChatupSetting/PrivacySetting'
import ChatSetting from './ChatupSetting/ChatSetting'
import NotificationSetting from './ChatupSetting/NotificationSetting'
import HelpSetting from './ChatupSetting/HelpSetting'
import InviteFriend from './ChatupSetting/InviteFriend'
import NewGroupsTab from './ChatupSetting/NewGroupTab'
import NewBroadcastTab from './ChatupSetting/NewBroadcastTab'
import StarredMessage from './ChatupSetting/StarredMessage'
import ThemeSetting from './ChatupSetting/ThemeSetting'
import MessageSidebar from './MessageSidebar'
import StatusSidebar from './StatusSidebar'
import StatusTab from './StatusTab'
import StatusSetting from './StatusSetting'
import ProfileTab from './ChatupTab/ProfileTab'
import ProfileShare from './ChatupTab/ProfileShare'
// import page100 from '../../../assets/images/page-img/100.jpg'

const Chat=()=>{

    const Localdata=JSON.parse(localStorage.getItem('ChatUp_Login'))
const [sidebar,setSidebar]=useState('message')
    const [activeTab,setActiveTab] = useState('')
    const [show, setShow] = useState('')
    const [show1, setShow1] = useState('')
    const [show12, setShow12] = useState('false')
 
    const [show2, setShow2] = useState('')
    const ChatSidebar =() =>{
        document.getElementsByClassName('scroller')[0].classList.add('show')
    } 
    const ChatSidebarClose =() =>{
        document.getElementsByClassName('scroller')[0].classList.remove('show')
    }
    
    const ChatSidebar2 =() =>{
        document.getElementsByClassName('scroller')[0].classList.add('show')
    } 
    const ChatSidebarClose2 =() =>{
        document.getElementsByClassName('scroller')[0].classList.remove('show')
    }
    return(
        <>
            <Tab.Container id="left-tabs-example"  defaultActiveKey="start">                        
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Body className="chat-page p-0">
                                <div className="chat-data-block">
                                    <Row>
                                        <Col lg="3" className="chat-data-left scroller">
                                            <div className="chat-search pt-3 ps-3">
                                                <div className='row align-items-center justify-content-center'>

                                                <div className="d-flex align-items-center col-md-6">
                                                    <div className="chat-profile me-3">
                                                        <img loading="lazy" src={user1} alt="chat-user" className="avatar-40 " onClick={() => setShow1('true')}/>
                                                    </div>
                                                    <div className="chat-caption">
                                                        <h5 className="mb-0">{Localdata?.fullname}</h5>
                                                        <p className="m-0">Web Designer</p>
                                                    </div>
                                                  
                                                </div>
                                                <div className='col-md-5 d-flex justify-content-between'>
                                                <i className="material-symbols-outlined md-18 me-1" title='Message' onClick={()=>setSidebar('message')}>message</i>
                                                
                                                <i className="material-symbols-outlined md-18 me-1" title='Status' onClick={()=>setSidebar('status')}>Cycle</i>
                                               
                                                <i className="material-symbols-outlined md-18 me-1" title='Status' onClick={()=>setShow12('true')}>qr_code_scanner</i>
                                                </div>
                                                <div className='col-md-1'>
                                                        <Dropdown className=" d-flex justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                        <Dropdown.Item className="d-flex align-items-center"   eventKey='eight' href="#user8" onClick={() => setShow('eight')}><i className="material-symbols-outlined md-18 me-1">group</i>New group</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='nine' href="#broadcast" onClick={() => setShow('nine')}><i className="material-symbols-outlined md-18 me-1">delete_outline</i>New broadcast</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">devices</i>Multi Device</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='ten' href="#user10" onClick={() => setShow('ten')}><i className="material-symbols-outlined md-18 me-1">star</i>Starred messages</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='seven' href="#user7" onClick={() => setShow('seven')}><i className="material-symbols-outlined md-18 me-1">settings</i>Settings</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="/" onClick={()=>localStorage.removeItem('ChatUp_Login')}><i className="material-symbols-outlined md-18 me-1">logout </i>Logout</Dropdown.Item>

                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                        </div>
                                                </div>


                                                <ProfileTab setShow1={setShow1} show1={show1} Localdata={Localdata} ChatSidebarClose={ChatSidebarClose}/>
                                                <ProfileShare setShow1={setShow12} show1={show12} Localdata={Localdata} ChatSidebarClose={ChatSidebarClose2}/>
                                               
                                                <div className="chat-searchbar mt-4">
                                                    <Form.Group className="form-group chat-search-data m-0">
                                                        <input type="text" className="form-control round" id="chat-search" placeholder="Search"/>
                                                            <i className="material-symbols-outlined">
                                                                search
                                                            </i>
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            {sidebar==='message'&& <MessageSidebar setShow={setShow}/>}
                                            {sidebar==='status'&& <StatusSidebar setShow={setShow}/>}

                                
                                        </Col>
                                        <Col lg={9} className=" chat-data p-0 chat-data-right">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="start" className="tab-pane fade show" id="default-block" role="tabpanel">
                                                    <div className="chat-start">
                                                        <span className="iq-start-icon text-primary"><i className="material-symbols-outlined md-42">sms</i></span>
                                                        <Button id="chat-start" onClick={ChatSidebar} bsPrefix="btn bg-white mt-3">Start
                                                        Conversation!</Button>
                                                    </div>
                                                </Tab.Pane>
                                                {tabData.map((tab, idx) => (
        <ChatTab key={idx} tab={tab} />
      ))}

<Tab.Pane eventKey='seven' className={`fade ${show ? 'show' : ''}`} id='user7' role="tabpanel">
      <div className="chat-head">
        <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
          <div className="d-flex align-items-center">
            <div className="sidebar-toggle">
              <i className="ri-menu-3-line"></i>
            </div>
            <div className="avatar chat-user-profile m-0 me-3">
              <img loading="lazy" src={user1} alt="avatar" className="avatar-50"   />
              <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
            </div>
            <h5 className="mb-0">User</h5>
          </div>
           
        </header>
      </div>

      <Row>
        <Col lg="4">
      <Card >
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Settings</h4>
                                </div>
                            </div>
                            <Card.Body className='scroller WebScroller' >
                                <ul className="media-story list-inline  m-0 p-0"  >
                                    <li className="d-flex mb-3 align-items-center" onClick={()=>setActiveTab('account')}>
                                    <i className="ri-account-pin-circle-line"></i>
                                        <div className="stories-data ms-3">
                                            <h5>Account</h5>
                                            <p className="mb-0">Security Notification,change number</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center active" onClick={()=>setActiveTab('Privacy')}>
                                    <i class="ri-git-repository-private-line"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5>Privacy</h5>
                                            <p className="mb-0">Block contents,disappearing messages</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center">
                                    <i class="ri-user-line"></i>
                                    
                                        <div className="stories-data ms-3">
                                            <h5>Avatar</h5>
                                            <p className="mb-0">Create,edit,profile photo</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center" onClick={()=>setActiveTab('Chat')}>
                                    <i class="ri-chat-1-line"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5>Chat</h5>
                                            <p className="mb-0">Theme, wallpapers,chat history</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center" onClick={()=>setActiveTab('notification')}>
                                    <i class="ri-notification-line"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5>Notifications</h5>
                                            <p className="mb-0">Messgae,group& call tones</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center" onClick={()=>setActiveTab('theme')}>
                                    <i class="ri-contrast-2-line"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5>Theme</h5>
                                            <p className="mb-0">ChatUP Theme,color</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center" onClick={()=>setActiveTab('Help')}>
                                    <i class="ri-questionnaire-line"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5>Help</h5>
                                            <p className="mb-0">Help centre,contect us,privacy policy</p>
                                        </div>
                                    </li>

                                    <li className="d-flex align-items-center" onClick={()=>setActiveTab('invite')}> 
                                    <i class="ri-links-line"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5>Invite a friend</h5>
                                             
                                        </div>
                                    </li>
                                    <li  style={{height:'40px'}}> 
                                     
                                    </li>
                                </ul>
            
                            </Card.Body>
                        </Card>
        </Col>
        <Col lg="8">
     {activeTab === "account" && <AccountSetting/>}
     {activeTab === "Privacy" && <PrivacySetting/>}
     {activeTab === "Chat" && <ChatSetting/>}
     {activeTab === "theme" && <ThemeSetting/>}
     {activeTab === "notification" && <NotificationSetting/>}
     {activeTab === "Help" && <HelpSetting/>}
     {activeTab === "invite" && <InviteFriend/>}
     
     

     
        </Col>
      </Row>
</Tab.Pane>

<NewGroupsTab show={show}/>
<StatusSetting show={show}/>
<NewBroadcastTab show={show}/>

<StarredMessage show={show}/>
<StatusTab show={show}/>



 
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                </Tab.Container>
        </>
    )
}
export default Chat;    