//router
// import IndexRouters from "./router/index"

//scss
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"


// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

// import state selectors
import { setSetting, theme_color } from './store/setting/actions'
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { setloaderFalse } from "./store/Auth/Slice";


function App(props) {
  const dispatch = useDispatch()
  dispatch(setSetting())
  // const {loading} = useSelector((i)=>i?.auth)

  // useEffect(()=>{
  //   setTimeout(()=>{
     
  //   },4000)
  // },[loading])


  
  const updateColor = (colorClassName, colorObj) => {
      dispatch(theme_color({value: colorClassName, colors: colorObj}))
  }



  const colorChange = (colorClassName, colorObj) => {
        {
          updateColor(colorClassName, colorObj)
      }
  }

  useEffect(()=>{
      console.log('change color');
     colorChange('theme-color-green', {"--{{prefix}}info": '#25C799',"--{{prefix}}primary": '#000000'})
  },[])







  return (
    <div className="App">
<ToastContainer/>
      {/* <IndexRouters /> */}
      {props.children}
    </div>
  );
}

export default App;
