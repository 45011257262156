import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

//img
import logo from "../../../assets/images/logo-white.png";
import QR from "../../../assets/images/QR.png"
import login1 from "../../../assets/images/login/1.png";
import login2 from "../../../assets/images/login/2.png";
import login3 from "../../../assets/images/login/3.png";
import { useDispatch, useSelector } from "react-redux";
import {
  ErrorClear,
  LoginAuthWithPhones,
  LoginAuths,
  setloadertrue,
} from "../../../store/Auth/Slice";
import { handleToast } from "../../../toast";
import Loading from "../../../Loading";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
  const [LoginWithPhone,setLoginWithPhone]=useState(false)
  const { error, loading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  let history = useNavigate();
  const initialValue = { emailOrContact: "", password: "" };
  // Validation schema using Yup
  const validationSchema = Yup.object({
    emailOrContact: Yup.string().required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const res = await dispatch(LoginAuthWithPhones({...values,emailOrContact:values.emailOrContact.toString()}));

    if (res.payload.isSuccess) {
      history("/chatup");
      dispatch(handleToast("Login success", "success"));
    }
  };

  useEffect(() => {
    if (error) {
      const errorMessage = error.message ? error.message[0] : error.error;
      dispatch(handleToast(errorMessage || "An error occurred", "error"));

      dispatch(ErrorClear());
    }
  }, [error, dispatch]);
                  
  const swicth = ()=>{
    setLoginWithPhone(!LoginWithPhone)
  }
  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>
        <Container className="p-0">
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5">
              <div className="sign-in-detail text-white">
                <Link className="sign-in-logo  mb-2" to="#">
                  <Image src={logo} className="img-fluid" alt="logo" />
                </Link>
                {/* <div className="sign-slider overflow-hidden">
                  <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    className="list-inline m-0 p-0"
                  >
                    <SwiperSlide>
                      <Image src={login1} className="img-fluid mb-4" alt="logo" />
                      <h4 className="mb-1 text-white">Find new friends</h4>
                      <p>
                        It is a long established fact that a reader will be distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Image src={login2} className="img-fluid mb-4" alt="logo" />
                      <h4 className="mb-1 text-white">Connect with the world</h4>
                      <p>
                        It is a long established fact that a reader will be distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <Image src={login3} className="img-fluid mb-4" alt="logo" />
                      <h4 className="mb-1 text-white">Create new events</h4>
                      <p>
                        It is a long established fact that a reader will be distracted by the readable content.
                      </p>
                    </SwiperSlide>
                  </Swiper>
                </div> */}

                <div>
                  <h2 className="text-white" > Use ChatUp on your computer</h2>
                  <ol className="text-start"  >
                    <li>
                      <h4 style={{color:'white'}}>Open ChatUp on your phone</h4>
                    </li>
                    <li>
                      <h4 style={{color:'white'}}>
                        Tab Menu{" "}
                        <span>
                        <svg height="24px" viewBox="0 0 24 24" width="24px">
  <rect
    fill="#333"
    height="24"
    rx="3"
    width="24"
  ></rect>
  <path
    d="m12 15.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0-2c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm0-5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5z"
    fill="#818b90"
  ></path>
</svg>
                        </span>
                        on Android,or setting 
                        <span>
                        <svg width="24" height="24" viewBox="0 0 24 24">
  <rect
    fill="#333"
    width="24"
    height="24"
    rx="3"
  ></rect>
  <path
    d="M12 18.69c-1.08 0-2.1-.25-2.99-.71L11.43 14c.24.06.4.08.56.08.92 0 1.67-.59 1.99-1.59h4.62c-.26 3.49-3.05 6.2-6.6 6.2zm-1.04-6.67c0-.57.48-1.02 1.03-1.02.57 0 1.05.45 1.05 1.02 0 .57-.47 1.03-1.05 1.03-.54.01-1.03-.46-1.03-1.03zM5.4 12c0-2.29 1.08-4.28 2.78-5.49l2.39 4.08c-.42.42-.64.91-.64 1.44 0 .52.21 1 .65 1.44l-2.44 4C6.47 16.26 5.4 14.27 5.4 12zm8.57-.49c-.33-.97-1.08-1.54-1.99-1.54-.16 0-.32.02-.57.08L9.04 5.99c.89-.44 1.89-.69 2.96-.69 3.56 0 6.36 2.72 6.59 6.21h-4.62zM12 19.8c.22 0 .42-.02.65-.04l.44.84c.08.18.25.27.47.24.21-.03.33-.17.36-.38l.14-.93c.41-.11.82-.27 1.21-.44l.69.61c.15.15.33.17.54.07.17-.1.24-.27.2-.48l-.2-.92c.35-.24.69-.52.99-.82l.86.36c.2.08.37.05.53-.14.14-.15.15-.34.03-.52l-.5-.8c.25-.35.45-.73.63-1.12l.95.05c.21.01.37-.09.44-.29.07-.2.01-.38-.16-.51l-.73-.58c.1-.4.19-.83.22-1.27l.89-.28c.2-.07.31-.22.31-.43s-.11-.35-.31-.42l-.89-.28c-.03-.44-.12-.86-.22-1.27l.73-.59c.16-.12.22-.29.16-.5-.07-.2-.23-.31-.44-.29l-.95.04c-.18-.4-.39-.77-.63-1.12l.5-.8c.12-.17.1-.36-.03-.51-.16-.18-.33-.22-.53-.14l-.86.35c-.31-.3-.65-.58-.99-.82l.2-.91c.03-.22-.03-.4-.2-.49-.18-.1-.34-.09-.48.01l-.74.66c-.39-.18-.8-.32-1.21-.43l-.14-.93a.426.426 0 00-.36-.39c-.22-.03-.39.05-.47.22l-.44.84-.43-.02h-.22c-.22 0-.42.01-.65.03l-.44-.84c-.08-.17-.25-.25-.48-.22-.2.03-.33.17-.36.39l-.13.88c-.42.12-.83.26-1.22.44l-.69-.61c-.15-.15-.33-.17-.53-.06-.18.09-.24.26-.2.49l.2.91c-.36.24-.7.52-1 .82l-.86-.35c-.19-.09-.37-.05-.52.13-.14.15-.16.34-.04.51l.5.8c-.25.35-.45.72-.64 1.12l-.94-.04c-.21-.01-.37.1-.44.3-.07.2-.02.38.16.5l.73.59c-.1.41-.19.83-.22 1.27l-.89.29c-.21.07-.31.21-.31.42 0 .22.1.36.31.43l.89.28c.03.44.1.87.22 1.27l-.73.58c-.17.12-.22.31-.16.51.07.2.23.31.44.29l.94-.05c.18.39.39.77.63 1.12l-.5.8c-.12.18-.1.37.04.52.16.18.33.22.52.14l.86-.36c.3.31.64.58.99.82l-.2.92c-.04.22.03.39.2.49.2.1.38.08.54-.07l.69-.61c.39.17.8.33 1.21.44l.13.93c.03.21.16.35.37.39.22.03.39-.06.47-.24l.44-.84c.23.02.44.04.66.04z"
    fill="#818b90"
  ></path>
</svg>
                        </span>
on iPhone
                      </h4>
                    </li>
                    <li>
                      <h4 style={{color:'white'}}>Tap Linked devices and then link a devices</h4>
                    </li>
                    <li>
                      <h4 style={{color:'white'}}>Point your phone at this screen to capture the QR code</h4>
                    </li>
                  </ol>
                </div>
              </div>
            </Col>
            <Col md="6" className="bg-white pt-5 pt-5 pb-lg-0 pb-5">
              <div className="sign-in-from text-center">
              {LoginWithPhone && <h1 className="mb-0">Sign in</h1>}
              { !LoginWithPhone &&<>  <h4>
                  Scan this QR code 
                </h4>
                <img src={QR} alt="QR.png"/></>}
                <Formik
                  initialValues={initialValue}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit} className="mt-4">
                    {LoginWithPhone && <>
                     <Form.Group className="form-group text-start">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="number"
                          name="emailOrContact"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.emailOrContact}
                          className="mb-0"
                          placeholder="Enter Phone Number"
                        />
                        {touched.emailOrContact && errors.emailOrContact ? (
                          <div className="error text-danger">{errors.emailOrContact}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group className="form-group text-start">
                        <Form.Label>Password</Form.Label>
                        <Link to="#" className="float-end">
                          Forgot password?
                        </Link>
                        <Form.Control
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          className="mb-0"
                          placeholder="Password"
                        />
                        {touched.password && errors.password ? (
                          <div className="error text-danger" >{errors.password}</div>
                        ) : null}
                      </Form.Group>
                      <div className="d-inline-block w-100">
                        <Form.Check className="d-inline-block mt-2 pt-1 text-start">
                          <Form.Check.Input
                            type="checkbox"
                            className="me-2"
                            id="customCheck11"
                          />
                          <Form.Check.Label>Remember Me</Form.Check.Label>{" "}
                        </Form.Check>
                        <Button
                          variant="primary"
                          type="submit"
                          className="float-end"
                          disabled={isSubmitting}
                        >
                         { loading  ? <Loading/>:"Sign in"}
                        </Button>
                      </div>
                     </>}

                 {  !LoginWithPhone &&   <div className="sign-info">
                        <span className="dark-color d-inline-block line-height-2">
                        <Link to="" onClick={swicth}> Sign in with Phone number {" "}
                           </Link>
                        </span>
                        
                      </div>}

                      {  LoginWithPhone &&   <div className="sign-info">
                        <span className="dark-color d-inline-block line-height-2">
                        <Link to="" onClick={swicth}> Sign in with QR code {" "}
                           </Link>
                        </span>
                        
                      </div>}
                      <div className="sign-info">
                        <span className="dark-color d-inline-block line-height-2">
                          Don't have an account?{" "}
                          <Link to="/auth/sign-up">Sign up</Link>
                        </span>
                        <ul className="iq-social-media">
                          <li>
                            <Link to="#">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                              >
                                <title>facebook</title>
                                <path d="M15 16h-14c-0.553 0-1-0.447-1-1v-14c0-0.553 0.447-1 1-1h14c0.553 0 1 0.447 1 1v14c0 0.553-0.447 1-1 1zM14 2h-12v12h12v-12zM8 6c0-1.103 0.912-2 1.857-2h1.143v2h-1v1h1v2h-1v3h-2v-3h-1v-2h1v-1z"></path>
                              </svg>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <title>twitter</title>
                                <path d="M20.833 5.262c-0.186 0.242-0.391 0.475-0.616 0.696-0.233 0.232-0.347 0.567-0.278 0.908 0.037 0.182 0.060 0.404 0.061 0.634 0 5.256-2.429 8.971-5.81 10.898-2.647 1.509-5.938 1.955-9.222 1.12 1.245-0.361 2.46-0.921 3.593-1.69 0.147-0.099 0.273-0.243 0.352-0.421 0.224-0.505-0.003-1.096-0.508-1.32-2.774-1.233-4.13-2.931-4.769-4.593-0.417-1.084-0.546-2.198-0.52-3.227 0.021-0.811 0.138-1.56 0.278-2.182 0.394 0.343 0.803 0.706 1.235 1.038 2.051 1.577 4.624 2.479 7.395 2.407 0.543-0.015 0.976-0.457 0.976-1v-1.011c-0.002-0.179 0.009-0.357 0.034-0.533 0.113-0.806 0.504-1.569 1.162-2.141 0.725-0.631 1.636-0.908 2.526-0.846s1.753 0.463 2.384 1.188c0.252 0.286 0.649 0.416 1.033 0.304 0.231-0.067 0.463-0.143 0.695-0.228zM22.424 2.183c-0.74 0.522-1.523 0.926-2.287 1.205-0.931-0.836-2.091-1.302-3.276-1.385-1.398-0.097-2.836 0.339-3.977 1.332-1.036 0.901-1.652 2.108-1.83 3.372-0.037 0.265-0.055 0.532-0.054 0.8-1.922-0.142-3.693-0.85-5.15-1.97-0.775-0.596-1.462-1.309-2.034-2.116-0.32-0.45-0.944-0.557-1.394-0.237-0.154 0.109-0.267 0.253-0.335 0.409 0 0-0.132 0.299-0.285 0.76-0.112 0.337-0.241 0.775-0.357 1.29-0.163 0.722-0.302 1.602-0.326 2.571-0.031 1.227 0.12 2.612 0.652 3.996 0.683 1.775 1.966 3.478 4.147 4.823-1.569 0.726-3.245 1.039-4.873 0.967-0.552-0.024-1.019 0.403-1.043 0.955-0.017 0.389 0.19 0.736 0.513 0.918 4.905 2.725 10.426 2.678 14.666 0.261 4.040-2.301 6.819-6.7 6.819-12.634-0.001-0.167-0.008-0.33-0.023-0.489 1.006-1.115 1.676-2.429 1.996-3.781 0.127-0.537-0.206-1.076-0.743-1.203-0.29-0.069-0.58-0.003-0.807 0.156z"></path>
                              </svg>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                viewBox="0 0 24 28"
                                fill="currentColor"
                              >
                                <title>instagram</title>
                                <path d="M16 14c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM18.156 14c0 3.406-2.75 6.156-6.156 6.156s-6.156-2.75-6.156-6.156 2.75-6.156 6.156-6.156 6.156 2.75 6.156 6.156zM19.844 7.594c0 0.797-0.641 1.437-1.437 1.437s-1.437-0.641-1.437-1.437 0.641-1.437 1.437-1.437 1.437 0.641 1.437 1.437zM12 4.156c-1.75 0-5.5-0.141-7.078 0.484-0.547 0.219-0.953 0.484-1.375 0.906s-0.688 0.828-0.906 1.375c-0.625 1.578-0.484 5.328-0.484 7.078s-0.141 5.5 0.484 7.078c0.219 0.547 0.484 0.953 0.906 1.375s0.828 0.688 1.375 0.906c1.578 0.625 5.328 0.484 7.078 0.484s5.5 0.141 7.078-0.484c0.547-0.219 0.953-0.484 1.375-0.906s0.688-0.828 0.906-1.375c0.625-1.578 0.484-5.328 0.484-7.078s0.141-5.5-0.484-7.078c-0.219-0.547-0.484-0.953-0.906-1.375s-0.828-0.688-1.375-0.906c-1.578-0.625-5.328-0.484-7.078-0.484zM24 14c0 1.656 0.016 3.297-0.078 4.953-0.094 1.922-0.531 3.625-1.937 5.031s-3.109 1.844-5.031 1.937c-1.656 0.094-3.297 0.078-4.953 0.078s-3.297 0.016-4.953-0.078c-1.922-0.094-3.625-0.531-5.031-1.937s-1.844-3.109-1.937-5.031c-0.094-1.656-0.078-3.297-0.078-4.953s-0.016-3.297 0.078-4.953c0.094-1.922 0.531-3.625 1.937-5.031s3.109-1.844 5.031-1.937c1.656-0.094 3.297-0.078 4.953-0.078s3.297-0.016 4.953 0.078c1.922 0.094 3.625 0.531 5.031 1.937s1.844 3.109 1.937 5.031c0.094 1.656 0.078 3.297 0.078 4.953z"></path>
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignIn;
