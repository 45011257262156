import user1 from '../../../assets/images/user/1.jpg'
import user5 from '../../../assets/images/user/05.jpg'
import user6 from '../../../assets/images/user/06.jpg'
import user7 from '../../../assets/images/user/07.jpg'
import user8 from '../../../assets/images/user/08.jpg'
import user9 from '../../../assets/images/user/09.jpg'
import user10 from '../../../assets/images/user/10.jpg'
export const tabData = [
    {
      eventKey: "first",
      id: "user1",
      header: "Team Discussions",
      userProfile: {
        avatar: user5,
        name: "Bni Jordan",
        location: "Cape Town, RSA",
        details: [
          { title: "Bni Name", value: "Bni" },
          { title: "Tel", value: "072 143 9920" },
          { title: "Date Of Birth", value: "July 12, 1989" },
          { title: "Gender", value: "Male" },
          { title: "Language", value: "English" }
        ]
      },
      messages: [
        {
          user: user1,
          time: "6:45",
          message: "How can we help? We're here for you! 😄"
        },
        {
          user: user5,
          time: "6:48",
          message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
        },
        // More messages...
      ]
    },
    {
      eventKey: "second",
      id: "user2",
      header: "Announcement",
      userProfile: {
        avatar: user6,
        name: "Mark Jordan",
        location: "Atlanta, USA",
        details: [
          { title: "Bni Name", value: "Mark" },
          { title: "Tel", value: "072 143 9920" },
          { title: "Date Of Birth", value: "July 12, 1989" },
          { title: "Gender", value: "Female" },
          { title: "Language", value: "English" },
        ],
      },
      messages: [
        {
          user: user1,
          time: "6:45",
          message: "How can we help? We're here for you! 😄"
        },
        {
          user: user6,
          time: "6:48",
          message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
        },
        {
            user: user1,
            time: "6:45",
            message: "How can we help? We're here for you! 😄"
          },
          {
            user: user6,
            time: "6:48",
            message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
          },
          {
            user: user1,
            time: "6:45",
            message: "How can we help? We're here for you! 😄"
          },
          {
            user: user6,
            time: "6:48",
            message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
          },
          {
            user: user1,
            time: "6:45",
            message: "How can we help? We're here for you! 😄"
          },
          {
            user: user6,
            time: "6:48",
            message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
          },
          {
            user: user1,
            time: "6:45",
            message: "How can we help? We're here for you! 😄"
          },
          {
            user: user6,
            time: "6:48",
            message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
          },
          {
            user: user1,
            time: "6:45",
            message: "How can we help? We're here for you! 😄"
          },
          {
            user: user6,
            time: "6:48",
            message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
          },
          {
            user: user1,
            time: "6:45",
            message: "How can we help? We're here for you! 😄"
          },
          {
            user: user6,
            time: "6:48",
            message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
          },
          {
            user: user1,
            time: "6:45",
            message: "How can we help? We're here for you! 😄"
          },
          {
            user: user6,
            time: "6:48",
            message: "Hey John, I am looking for the best admin template.\nCould you please help me to find it out? 🤔"
          },
        // More messages...
      ]
    },
    // Additional entries
    {
      eventKey: "third",
      type:'Group',
      id: "user3",
      header: "Casual Conversation",
      userProfile: {
        avatar: user7,
        name: "Alex Smith",
        location: "London, UK",
        details: [
          { title: "Bni Name", value: "Alex" },
          { title: "Tel", value: "072 143 9920" },
          { title: "Date Of Birth", value: "September 5, 1990" },
          { title: "Gender", value: "Male" },
          { title: "Language", value: "English" }
        ]
      },
      messages: [
        {
          user: user1,
          time: "7:00",
          message: "Hey, how's it going?"
        },
        {
          user: user7,
          time: "7:05",
          message: "Not bad, just relaxing. What about you?"
        },
        {
            user: user1,
            time: "8:30",
            message: "Could you provide an update on the project status?"
          },
          {
            user: user8,
            time: "8:35",
            message: "Sure, I'll send you the details shortly."
          },
           {
            user: user1,
            time: "9:00",
            message: "Any thoughts on the latest tech trends?"
          },
          {
            user: user9,
            time: "9:05",
            message: "I think AI and blockchain are quite promising."
          },
        // More messages...
      ]
    },
    {
      eventKey: "forth",
      id: "user4",
      header: "Project Updates",
      userProfile: {
        avatar: user8,
        name: "Emily Johnson",
        location: "Sydney, Australia",
        details: [
          { title: "Bni Name", value: "Emily" },
          { title: "Tel", value: "072 143 9920" },
          { title: "Date Of Birth", value: "December 10, 1985" },
          { title: "Gender", value: "Female" },
          { title: "Language", value: "English" }
        ]
      },
      messages: [
        {
          user: user1,
          time: "8:30",
          message: "Could you provide an update on the project status?"
        },
        {
          user: user8,
          time: "8:35",
          message: "Sure, I'll send you the details shortly."
        },
        // More messages...
      ]
    },
    {
        eventKey: "five",
        id: "user5",
        header: "Tech Discussions",
        userProfile: {
          avatar: user9,
          name: "Ethan Miller",
          location: "San Francisco, USA",
          details: [
            { title: "Bni Name", value: "Ethan" },
            { title: "Tel", value: "072 143 9920" },
            { title: "Date Of Birth", value: "March 25, 1987" },
            { title: "Gender", value: "Male" },
            { title: "Language", value: "English" }
          ]
        },
        messages: [
          {
            user: user1,
            time: "9:00",
            message: "Any thoughts on the latest tech trends?"
          },
          {
            user: user9,
            time: "9:05",
            message: "I think AI and blockchain are quite promising."
          },
          // More messages...
        ]
      },
      {
        eventKey: "six",
        id: "user6",
        header: "Customer Support",
        userProfile: {
          avatar: user10,
          name: "Sophia Williams",
          location: "Toronto, Canada",
          details: [
            { title: "Bni Name", value: "Sophia" },
            { title: "Tel", value: "072 143 9920" },
            { title: "Date Of Birth", value: "November 18, 1992" },
            { title: "Gender", value: "Female" },
            { title: "Language", value: "English" }
          ]
        },
        messages: [
          {
            user: user1,
            time: "10:00",
            message: "I'm experiencing some issues with the app. Can you assist?"
          },
          {
            user: user10,
            time: "10:05",
            message: "Sure, I'll do my best to help you resolve them."
          },
          // More messages...
        ]
      }
  ];
  


  export const channels = [
    {
        type: 'public',
        eventKey: "first",
        onClick: 'first',
        href: "#user1",
        avatar: user5,
        name: "Team Discussions",
        statusText: "Lorem Ipsum is",
        counter: 20,
        time: "05 min"
    },
    {
        type: 'public',
        eventKey: "second",
        onClick: 'second',
        href: "#user2",
        avatar: user6,
        name: "Announcement",
        statusText: "This Sunday we",
        counter: 10,
        time: "10 min"
    },
    {
        type: 'private',
        eventKey: "third",
        onClick: 'third',
        href: "#user3",
        avatar: user7,
        name: "Designer Group",
        statusText: "There are many",
    },
    {
        type: 'private',
        eventKey: "forth",
        onClick: 'forth',
        href: "#user4",
        avatar: user8,
        name: "Developer Group",
        statusText: "passages of Lorem",
    },
    {
        type: 'private',
        eventKey: "five",
        onClick: 'five',
        href: "#user5",
        avatar: user9,
        name: "Testing Team",
        statusText: "Lorem Ipsum used",
    },
    {
        type: 'direct',
        eventKey: "six",
        onClick: 'six',
        href: "#user6",
        avatar: user10,
        name: "Paul Molive",
        statusText: "translation by",
    },
    // Add more channel objects as needed
];


