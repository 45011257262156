import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const WithAuth = () => {
  const isAuthenticated = JSON.parse(localStorage.getItem("ChatUp_Login"));

  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/sign-in" />;
};

export default WithAuth;
    