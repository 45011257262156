 
import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown} from 'react-bootstrap'
 


import { channels, tabData } from './Chatdata'
 

const MessageSidebar = ({setShow}) => {

    const Localdata=JSON.parse(localStorage.getItem('ChatUp_Login'))

    const [activeTab,setActiveTab] = useState('')
    
    const [show1, setShow1] = useState('')
    const [show2, setShow2] = useState('')
    const ChatSidebar =() =>{
        document.getElementsByClassName('scroller')[0].classList.add('show')
    } 
    const ChatSidebarClose =() =>{
        document.getElementsByClassName('scroller')[0].classList.remove('show')
    }
  return (
       <div className="chat-sidebar-channel scroller mt-4 ps-3">
    <h5>Direct Messages</h5>
    <Nav as="ul" variant="pills" className="iq-chat-ui nav flex-column">
        {channels.filter(channel => channel.type === 'public').map(channel => (
            <Nav.Item as="li" key={channel.eventKey}>
                <Nav.Link eventKey={channel.eventKey} onClick={() => setShow(channel.onClick)} href={channel.href}>
                    <div className="d-flex align-items-center">
                        <div className="avatar me-2">
                            <img loading="lazy" src={channel.avatar} alt="chatuserimage" className="avatar-50" />
                            <span className="avatar-status"><i className="material-symbols-outlined text-success md-14 filled">circle</i></span>
                        </div>
                        <div className="chat-sidebar-name">
                            <h6 className="mb-0">{channel.name}</h6>
                            <span>{channel.statusText}</span>
                        </div>
                        <div className="chat-meta float-right text-center mt-2 me-1">
                            <div className="chat-msg-counter bg-primary text-white">{channel.counter}</div>
                            <span className="text-nowrap">{channel.time}</span>
                        </div>
                    </div>
                </Nav.Link>
            </Nav.Item>
        ))}
    </Nav>
    <h5 className="mt-3">Groups</h5>
    <Nav variant="pills" className="iq-chat-ui nav flex-column">
        {channels.filter(channel => channel.type === 'private').map(channel => (
            <Nav.Item as="li" key={channel.eventKey}>
                <Nav.Link eventKey={channel.eventKey} onClick={() => setShow(channel.onClick)} href={channel.href}>
                    <div className="d-flex align-items-center">
                        <div className="avatar me-2">
                            <img loading="lazy" src={channel.avatar} alt="chatuserimage" className="avatar-50" />
                            <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-warning"></i></span>
                        </div>
                        <div className="chat-sidebar-name">
                            <h6 className="mb-0">{channel.name}</h6>
                            <span>{channel.statusText}</span>
                        </div>
                    </div>
                </Nav.Link>
            </Nav.Item>
        ))}
    </Nav>
    <h5 className="mt-3">Public Channels</h5>
    <Nav variant="pills" className="iq-chat-ui nav flex-column">
        {channels.filter(channel => channel.type === 'direct').map(channel => (
            <Nav.Item as="li" key={channel.eventKey}>
                <Nav.Link eventKey={channel.eventKey} onClick={() => setShow(channel.onClick)} href={channel.href}>
                    <div className="d-flex align-items-center">
                        <div className="avatar me-2">
                            <img loading="lazy" src={channel.avatar} alt="chatuserimage" className="avatar-50" />
                            <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-dark"></i></span>
                        </div>
                        <div className="chat-sidebar-name">
                            <h6 className="mb-0">{channel.name}</h6>
                            <span>{channel.statusText}</span>
                        </div>
                    </div>
                </Nav.Link>
            </Nav.Item>
        ))}
    </Nav>
</div> 
  )
}

export default MessageSidebar