
const IsLogin =JSON.parse(localStorage.getItem('IsLoginData'))

const config = {
    userProfile : [{
      username: "john_doe",
      name: "John Doe",
      email: "john@gmail.com",
      password: "john@123",
      profilePic: "https://example.com/profilepic.jpg",
      phoneNumber: "+1234567890",
      address: {
        street: "123 Main St",
        city: "Anytown",
        state: "State",
        country: "Country",
        zipCode: "12345",
      },
      role:'user'
    },
    {
      username: "broker_doe",
      name: "broker Doe",
      email: "broker@gmail.com",
      password: "broker@123",
      profilePic: "https://example.com/profilepic.jpg",
      phoneNumber: "+1234567890",
      address: {
        street: "123 Main St",
        city: "Anytown",
        state: "State",
        country: "Country",
        zipCode: "12345",
      },
      role:'broker'
    }],
      IsLogin:IsLogin 



  };
  

export const ISloginRole =JSON.parse(localStorage.getItem('role'))
 export const GetKey  =  ISloginRole?.name|| ''
 

 

 export const BaseUrl = "https://socialapi.meander.software/"
 export const Active_Mode = '664c24b512e0fda64e7d177e'
 export const User_roleID = '664c24dd12e0fda64e7d1780'

 
  export default config;